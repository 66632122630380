import React from "react";
import LayoutComponent from "../../../components/documentation-layout/Layout";

const RunningExecution = () => {
  return (
    <LayoutComponent>
      <h3>Running an Automation</h3>
      <div>
        <p>Follow these steps to run an automation in GAINSS:</p>
        <ol>
          <li>
            Navigate to the <strong>Execute Test Cycle</strong> screen.
          </li>
          <li>
            Ensure that the <strong>Test Cycle Type</strong> is set to <strong>Automation</strong> and a <strong>Job/Workflow</strong> is assigned to the test cycle. If so, you will see a <strong>Run Automation</strong> button.
          </li>
          <li>
            Select the desired iteration for which you want to run the automation.
          </li>
          <li>
            Click the <strong>Run Automation</strong> button.
          </li>
          <li>
            The system will check the build status of any previous builds for the selected iteration:
            <ul>
              <li>If a previous build exists, it will display the relevant status.</li>
              <li>If no previous build exists, you can proceed to trigger the job/workflow.</li>
            </ul>
          </li>
          <li>
            Click <strong>Run</strong> to trigger the job or workflow. 
          </li>
          <li>
            Once the automation is successfully triggered, the popup will close, and a success notification will appear.
          </li>
          <li>
            If there is already a build in progress for the selected iteration, the system will display the build status as <strong>Running</strong>.
          </li>
          <li>
            For a running build, you will also have the option to <strong>Abort</strong> the build if needed.
          </li>
        </ol>
      </div>
    </LayoutComponent>
  );
};

export default RunningExecution;
